import React from "react"
import { Container, Grid } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ExtensaoPage(props) {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title="Extensão" />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Extensão</PageTitle>
        <Grid container style={{ marginTop: 70 }}>
          <Grid xs={12} sm={12}>
            <p>
              Na Faculdade Jardins, desde o seu Credenciamento, as atividades de extensão e de iniciação científica e tecnológica são disponibilizadas regularmente aos alunos, sendo obrigatórias ao longo dos cursos de graduação, ou seja, curricularizadas.
              Recentemente, com o advento da Resolução CNE/CES nº 07/2018, que estabeleceu a obrigatoriedade da curricularização da extensão, potencializamos a oferta da mesma utilizando estratégias para sua oferta curricularizada nas matrizes da graduação e na pós-graduação, além de ofertadas à comunidade, se valendo, inclusive, de nossa estrutura tecnologia e virtual para a oferta via e-jardins (portal AVA) e com apoio dos Polos para regionalização da oferta, contemplando todas as áreas de formação oferecidas.
              Dentre as estratégias para oferta da extensão curricularizada nas matrizes de graduação com pelo menos 10% da carga horária total, conforme prevê a Resolução CNE/CES nº 07/2018, além das Atividades Complementares que já cumpriam esse papel conforme regulamentação própria, o Projeto de Formação e Carreira (PFC), uma metodologia ativa inovadora e exitosa da FacJardins, é a principal estratégia da IES para cumprir essa obrigação legal de curricularização da extensão, que por sua vez promove também a curricularização da iniciação científica e tecnológica.
              Participe de nossas Práticas de Extensão que visam te oportunizar a ampliação de saberes e a aproximação com a sociedade. Certamente, os conhecimentos que serão adquiridos contribuirão para sua formação pessoal e profissional.

            </p>
            <p><strong>As práticas de Extensão da Faculdade Jardins são desenvolvidas sob as seguintes formas:</strong></p>
            <br />
          </Grid>
          <Grid xs={12} sm={12}>
            <h2><strong><u>EXTENSÃO - EVENTOS</u></strong></h2>
          </Grid>
          <Grid xs={12} sm={12}>
            <p>
              <strong>Fomentando Ideias</strong>
            </p>
            <p>
              A proposta desse evento tem como objetivo geral oportunizar o conhecimento sobre ensino-intervenção visando à reorientação da formação profissional. Pretende envolver os alunos para que planejem um Evento e convidem profissionais das áreas de formação para que contribuam com palestra de temas atuais e experiências profissionais, visa, também, contribuir para o aprendizado do aluno quanto ao planejamento, organização, gestão, entre outros.
            </p>
            <p><strong>Coordenadoras:</strong> Professoras Adenilda Couto e Cândia Maria Mendes </p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Feira de Educação Ambiental</strong>
            </p>
            <p>
              A proposta da Feira tem como objetivo geral promover ações de Educação Ambiental, na perspectiva da transversalidade, visando despertar nos discentes e comunidade atitudes de preservação do meio ambiente. Acredita-se que a Educação Ambiental por ser um processo de reflexão acerca das atitudes frente ao meio ambiente, permitirá aos discentes e comunidade, do seu entorno, reflexões dos problemas que comprometem o meio ambiente na perspectiva de mudanças de posturas.
            </p>
            <p><strong>Coordenadoras:</strong> Professoras Alaide Martins e Luzia Barreto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Diversidades e Inclusão</strong>
            </p>
            <p>
              Esta proposta visa envolver os alunos em atividades socioeducativas e culturais que permitam a reflexão quanto valorização das diferenças nos diversos espaços da sociedade, a troca de saberes e experiências visando rever paradigmas, modificar, reformular conceitos e atitudes, bem como compreender o seu papel na sociedade diante das diferenças. Tem como objetivo geral sensibilizar o público-alvo quanto ao respeito ao próximo e aceitação das diferenças.
            </p>
            <p><strong>Coordenadoras:</strong>Professoras Adenilda Couto e Cândia Maria Mendes </p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Jardins Carreira</strong>
            </p>
            <p>
              Está proposta permitirá ao discente levar o que aprendeu em sala de aula através de oficinas para estudantes do 3º ano do ensino médio. A proposta do projeto visa através de oficinas levar temáticas relacionadas ao processo de entrada no mercado de trabalho, tais como: currículo profissional, entrevista de emprego e comportamento no ambiente de trabalho, relacionamento interpessoal, habilidades interpessoais, comunicação eficaz no ambiente de trabalho, psicologia positiva, objetivando aumentar o grau de empregabilidade dos jovens que estão no terceiro ano do ensino médio. Tem como objetivo oportunizar aos estudantes do 3º ano do ensino médio o conhecimento acerca da importância da formação profissional continua.
            </p>
            <p><strong>Coordenadores:</strong> Professor Almir Martins, Adenilda Pinto e Cândida Maria Mendes</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Quarta Cultural</strong>
            </p>
            <p>
              Os encontros são realizados todas as últimas quartas-feiras de cada mês e para fortalecer esses momentos, contará com a presença de docentes da Faculdade Jardins e convidados que favorecerão a consolidação de um espaço formativo e de interação entre a comunidade escolar, em uma perspectiva interdisciplinar, o que possibilitará a circulação de diferentes temáticas presentes no cotidiano dos participantes. Tem como objetivo oportunizar aos discentes a ampliação de saberes que contribuam para sua formação pessoal e profissional.
            </p>
            <p><strong>Coordenadoras:</strong>Professoras Adenilda Couto, Alaide Martins, Cândia Maria Mendes e Luzia Barreto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <h2><strong><u>EXTENSÃO - PROJETOS</u></strong></h2>
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Projeto - Responsabilidade Social</strong>
            </p>
            <p>
              Esta proposta visa incentivar os alunos quanto a reflexão e criticidade em relação ao seu papel na sociedade e compromisso com o desenvolvimento local, regional e nacional, com o voluntariado, com os direitos humanos, cidadania, ética, entre outros. Desta forma, os alunos formarão grupos e farão ações interventivas em uma instituição, escola, Ong, empresa de sua escolha. Tem como objetivo geral despertar nos alunos o interesse por ações de responsabilidade social.
            </p>
            <p><strong>Coordenadoras:</strong> Professoras Adenilda Couto, Alaide Martins, Cândia Maria Mendes e Luzia Barreto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Projeto - FacJardins DAY</strong>
            </p>
            <p>
              A vivência por um dia será supervisionada por um profissional da Faculdade/Polo e profissionais da Escola que receberá o aluno. Essa vivência oportunizará reflexões, práticas e aprendizados para a formação cidadã, profissional e ética diante da realidade docente. Tem como objetivo proporcionar aos alunos dos cursos de licenciatura da Faculdade Jardins vivenciar a rotina do professor da sua área de formação por um dia
            </p>
            <p><strong>Coordenadoras:</strong> Professoras Adenilda Couto, Alaide Martins, Cândia Maria Mendes e Luzia Barreto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Projeto - Jardins Sustentabilidade</strong>
            </p>
            <p>
              Esta proposta visa desenvolver ações de sustentabilidade por meio da educação, promovendo reflexões para otimizar a relação da comunidade com o meio ambiente. Desta forma, os alunos formarão grupos e farão ações interventivas em uma escola, ONG, associações de moradores entre outros. Ao identificar as problemáticas ambientais da comunidade, os alunos desenvolverão ações de educação ambiental sob a orientação do professor/tutor visando mudança na realidade estudada. Tem como objetivo promover ações socioambientais para o desenvolvimento de práticas sustentáveis através da educação.
            </p>
            <p><strong>Coordenadoras:</strong>Professoras Alaide Martins e Luzia Barreto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Projeto - FacJardins na Escola</strong>
            </p>
            <p>
              É um projeto de extensão através de parcerias com Escolas Públicas e Privadas do Ensino Médio localizadas no entorno da Faculdade e de alguns Polos. A proposta contemplará a comunidade, bem como os discentes da IES que terão a oportunidade de estar mais próximo da realidade da comunidade. Tem como objetivo promover ações socioeducativas que contribuam para a formação, desenvolvimento pessoal e profissional dos estudantes do 3.º ano do ensino médio de escolas públicas e privadas do entorno da Faculdade e de Polos.
            </p>
            <p><strong>Coordenadoras:</strong>Professoras Alaide Martins e Cândia Maria Mendes.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Projeto - Filme na Sala de Aula: Discutindo a Atuação Profissional</strong>
            </p>
            <p>
              Esta proposta visa através da utilização de filmes a discussão e reflexão que favoreçam com conhecimentos e aprendizagens diferenciadas para a formação profissional. Espera-se que a partir dos filmes com abordagens relacionadas a área de formação do discente favoreça a relação teoria-prática, bem como a revisão dos conteúdos que compõem a proposta curricular do curso. O objetivo desta proposta é analisar a prática profissional a partir das cenas exibidas nos filmes.
            </p>
            <p><strong>Coordenadora:</strong>Professora Adenilda Couto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              <strong>Projeto – Conexão Jardins</strong>
            </p>
            <p>
              Está proposta permitirá ao discente levar o que aprendeu em sala de aula através de orientações para a prática do dia a dia de empresários da região oferecendo gratuitamente orientações que favorecerá encontrar caminhos através de marketing digital, e-commerce, entre outros buscando a superação de crise econômica. Aos discentes o projeto oportunizará aprendizados e enriquecimento do currículo, dentro da área de formação e para além da sala de aula de forma transversal e interdisciplinar.
            </p>
            <p><strong>Coordenadores:</strong> Professor Almir Martins, Adenilda Pinto e Cândida Maria Mendes.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <h2><strong><u>EXTENSÃO - CURSOS</u></strong></h2>
          </Grid>

          <Grid xs={12} sm={12}>
            <p>
              A oferta de cursos ocorrerá duas vezes ao ano e não se restringirá aos alunos, professores, tutores e colaboradores da Faculdade, mas contemplará toda a comunidade que tenha interesse. A proposta extensionista Quarentena Com Cursos tem como objetivo a ampliação do conhecimento quanto as competências e habilidades tão necessárias para se manter atuante no mercado de trabalho. Os cursos podem ser de forma presencial ou virtual.
            </p>
            <p><strong>Coordenadoras:</strong>Professoras Adenilda Pinto, Alaide Martins e Luzia Barreto.</p>
            <br />
          </Grid>

          <Grid xs={12} sm={12}>
            <h2><strong><Link style={{ color: "#007bff" }} to="/parceiros"><u>CONHEÇA NOSSOS PARCEIROS</u></Link></strong></h2>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}



